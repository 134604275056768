// 判断是否为移动端
export const isMobile = getPlatform()

function getPlatform() {
  // let flag = navigator.userAgent.includes(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
  let flag = /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/gi.test(navigator.userAgent)
  return flag;
}

// 首屏动画
export const img = require('@/assets/home/CRWV.png')
// 公司简介图片
export const chatu01 = require('@/assets/image/chatu01.png')
export const chatu02 = require('@/assets/home/iddianshang.png')
// 品牌价值观
export const brandValues = require('@/assets/image/magicsword.png')

// LOGO
export const magicLogo = 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/navLogo.png'
export const chooseReasonsBg = 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/joinbg1.png'

// 导航菜单
export const menuData = [
  {
    name: "home",
    label: "首页",
    icon: 'el-icon-s-home'
  },
  {
    name: "intelligent",
    label: "智能直播",
    icon: 'el-icon-video-camera-solid'
  },
  // {
  //   name: "virtual",
  //   label: "虚拟直播",
  //   icon: 'el-icon-s-marketing'
  // },
  {
    name: "robot",
    label: "智能机器人",
    icon: 'el-icon-s-marketing'
  },
  {
    name: "content",
    label: "内容生产",
    icon: 'el-icon-s-order'
  },
  // {
  //   name: "business",
  //   label: "创意营销",
  //   icon: 'el-icon-s-opportunity'
  // },

  {
    name: "about",
    label: "关于我们",
    icon: 'el-icon-s-custom'
  },
  {
    name: "join",
    label: "加入我们",
    icon: 'el-icon-s-check'
  }
]

export const ProductIntroduction = {
  // 首页介绍
  intelligent: {
    title: '智能直播',
    name: 'intelligent',
    // content: '迈吉客综合运用AI技术实现了业界首创的全智能虚拟主播，结合电商品牌及店铺构建的商品信息知识库，智能虚拟电商主播能以活灵活现的表情动作、语音语调进行商品销售，并根据用户的需求答疑解惑、智能互动， 7*24小时不间断服务，成为与真人主播优势互补的营销利器。',
    content: '迈吉客科技综合运用AI技术实现了业界首创的全智能虚拟主播，以活灵活现的表情动作、语音语调，并结合品牌知识库和品类知识图谱，与用户进行多模态智能互动，7*24小时不间断为客户提供服务、答疑解惑，成为与真人主播优势互补的数字化身。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/webcast.png',
    lineColor: '#648EE8'
  },
  robot: {
    title: '智能机器人',
    name: 'robot',
    content: '通过自研的虚拟化技术，助力企业（品牌）实现跨行业跨平台、无处不在的智能数字员工，并且为用户提供个性化VIP专属服务。从静态变为智态，从单向输出变为双向交互，从5*8到7*24小时，从单语言到多语言，从“一对多”到“一对一”，从现实世界到混合虚拟现实结合。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/robot.png',
    lineColor: '#FF0D7A'
  },
  content: {
    title: '内容生产工具',
    name: 'content',
    // content: '迈吉客科技的内容生产工具尝试将影视动画CG技术消费化，把动画内容的生产门槛降低，让动画内容可以“演”出来。透过在混合现实仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新和应用场景探索，Magics工具赋予IP生命，为合作动画公司的虚拟形象提供定制版工具，帮助其提高内容生产力并持续降低生产成本，以“日更”甚至不止“日更”的高频更新快速抢占用户注意力，积累粉丝、沉淀私域流量，并通过内容付费与粉丝打赏及带货模式获得收入。',
    // content: '迈吉客科技的内容生产工具尝试将影视动画CG技术消费化，把动画内容的生产门槛降低，让动画内容可以“演”出来。<br/>透过在混合现实仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新和应用场景探索，Magics工具赋予IP生命，为合作动画公司的虚拟形象提供定制版工具，帮助其提高内容生产力并持续降低生产成本，以“日更”甚至不止“日更”的高频更新快速抢占用户注意力，积累粉丝、沉淀私域流量，并通过内容付费与粉丝打赏及带货模式获得收入。',
    content: '迈吉客科技的内容生产工具尝试将影视动画CG技术消费化，把动画内容的生产门槛降低，将图文内容快速生成可视化内容。<br/>透过在混合现实仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新和应用场景探索，Magics工具赋予IP生命，赋能其活灵活现的“演绎”内容，实现高频零门槛、千人千面、视觉化互动的内容生产，帮助品牌品牌立体化呈现、媒体形式升级、营销内容高效生产。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/%E5%86%85%E5%AE%B9%E7%94%9F%E4%BA%A7.png',
    lineColor: '#FFA60D'
  },
  // virtual: {
  //   title: '虚拟直播',
  //   name: 'virtual',
  //   content: '2016年下半年迈吉客科技推出全球首例二次元虚拟直播，之后持续探索应用场景，迭代及打磨产品技术，透过在仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新，迈吉客科技的虚拟直播工具赋予IP生命，使其打破次元壁与真人实时互动；同时结合电商供应链资源，提供生产力工具和直播带货的双重赋能，落地“养成式变现”的商业模式。真人+IP虚拟直播极大增强了商品推广的趣味性（避免尬聊），以动画形式实现了更丰富的用户体验，在增加粉丝数量和活跃度的同时有极强的销售转化效果，真正实现边“养成”边“变现”的IP商业化路径。',
  //   mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/xunizhibo.png',
  //   lineColor:'#FF0D7A'
  // },
  // creative: {
  //   title: '创意营销',
  //   name: 'business',
  //   content: '2016年下半年迈吉客科技推出全球首例二次元虚拟直播，之后持续探索应用场景，迭代及打磨产品技术，透过在仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新，迈吉客科技的虚拟直播工具赋予IP生命，使其打破次元壁与真人实时互动；同时结合电商供应链资源，提供生产力工具和直播带货的双重赋能，落地“养成式变现”的商业模式。真人+IP虚拟直播极大增强了商品推广的趣味性（避免尬聊），以动画形式实现了更丰富的用户体验，在增加粉丝数量和活跃度的同时有极强的销售转化效果，真正实现边“养成”边“变现”的IP商业化路径。',
  //   mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/mulNew.png',
  //   lineColor:'#0DFFFF'
  // },
  CreativeMarketing: {
    title: '智能创意营销',
    content: '创意营销领域，迈吉客科技SDK产品线赋能OTT智能电视厂商、牌照方以及移动流量媒体类合作伙伴，以“动画弹幕”模式的创意广告能力升级用户体验，共同推动创意智能化的营销模式和市场，与品牌及流量媒体实现价值共赢。Magics创造的升维互动式用户体验，高效低成本的物料生产模式，创意组合智能化的投放能力，旨在帮助品牌的理念和产品实现更立体化、动态视觉化的传播, 并协助流量媒体实现全新的用户体验及额外的商业价值。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/mulNew.png',
    lineColor: '#0DFFFF'
  },
  // 详情页介绍
  liveBroadcast: {
    title: 'AI智能虚拟直播',
    content: '迈吉客科技综合运用AI技术实现了业界首创的全智能虚拟主播，以活灵活现的表情动作、语音语调，并结合品牌知识库和品类知识图谱，与用户进行多模态智能互动，7*24小时不间断为客户提供服务、答疑解惑，成为与真人主播优势互补的数字化身。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/webcast.png',
    lineColor: '#648EE8'
  },
  intelligentRobot: {
    title: '智能虚拟服务机器人',
    content: '通过自研的虚拟化技术，助力企业（品牌）实现跨行业跨平台、无处不在的智能数字员工，并且为用户提供个性化VIP专属服务。从静态变为智态，从单向输出变为双向交互，从5*8到7*24小时，从单语言到多语言，从“一对多”到“一对一”，从现实世界到混合虚拟现实结合。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/robot.png',
    lineColor: '#FF0D7A'
  },
  ContentProduction: {
    title: '内容生产工具',
    // content: '迈吉客科技的内容生产工具尝试将影视动画CG技术消费化，把动画内容的生产门槛降低，让动画内容可以“演”出来。<br/>透过在混合现实仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新和应用场景探索，Magics工具赋予IP生命，为合作动画公司的虚拟形象提供定制版工具，帮助其提高内容生产力并持续降低生产成本，以“日更”甚至不止“日更”的高频更新快速抢占用户注意力，积累粉丝、沉淀私域流量，并通过内容付费与粉丝打赏及带货模式获得收入。',
    content: '迈吉客科技的内容生产工具尝试将影视动画CG技术消费化，把动画内容的生产门槛降低，将图文内容快速生成可视化内容。<br/>透过在混合现实仿真融合、表情/动作捕捉与实时自然交互等方面的技术创新和应用场景探索，Magics工具赋予IP生命，赋能其活灵活现的“演绎”内容，实现高频零门槛、千人千面、视觉化互动的内容生产，帮助品牌品牌立体化呈现、媒体形式升级、营销内容高效生产。',
    mulImg: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/%E5%86%85%E5%AE%B9%E7%94%9F%E4%BA%A7.png',
    lineColor: '#FFA60D'
  }
}

// 虚拟直播-虚拟直播展示视频
export const virtualVideoList = [
  // {
  //   img:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/bangwei.png',
  //   url:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/bangwei.mp4'
  // },
  // {
  //   img:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/chuangwei.png',
  //   url:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/chuangwei.mp4'
  // },
  // {
  //   img:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/xiaosajie.png',
  //   url:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/xiaosajie.mp4'
  // }
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/bangwei.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/SAMSUNG.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/chuangwei.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/P&G_JD.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/xiaosajie.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/oceantalk.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/bangwei.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/DOVE.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/chuangwei.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/DJI.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/xiaosajie.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/adobe.mp4'
  }
]

// 助力品牌实现视觉化/人格化创意传播
export const assistanceModeList = [
  {
    label: '圣元',
    card: require('@/assets/image/card1.png'),
    animate: 'fadeInLeft',
    title: '品牌自有IP沉淀',
    text: '双重价值挖掘',
    video: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/%E5%9C%A3%E5%85%83%E5%A5%B6%E7%B2%89.mp4'
  },
  {
    label: 'Teddy',
    card: require('@/assets/image/card2.png'),
    animate: 'fadeInRight',
    title: 'IP/KOL流量整合',
    text: '借力流量红利价值洼地',
    video: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Teddy.mp4'
  },
  {
    label: 'zippo',
    card: require('@/assets/image/card3.png'),
    animate: 'fadeInLeft',
    title: '创意内容运营/传播',
    text: '奇思妙想病毒式传播',
    video: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Zippo.mp4'
  },
  {
    label: "企鹅娘",
    card: require('@/assets/image/card4.png'),
    animate: 'fadeInRight',
    title: '混合现实线下活动',
    text: '产品发布会、直播等',
    video: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/%E4%BC%81%E9%B9%85%E5%A8%981.mp4'
  },
  {
    label: "karen",
    card: require('@/assets/image/card5.png'),
    animate: 'fadeInLeft',
    title: '私域流量 营销闭环',
    text: '资源复用持续提升ROI',
    video: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/%E9%98%85%E8%AF%BB%E4%B8%8E%E5%A4%A7%E8%84%91.mp4'
  }
]

// 形神合一，智能问答
export const advantageImgs = {
  title: require('@/assets/advantage/title.png'),
  sub_title: require('@/assets/advantage/sub_title.png'),
  persona: require('@/assets/advantage/persona.png'),
  bgImg: require('@/assets/advantage/diamond_bg.png'),
  virtual: require('@/assets/advantage/virtual.png'),
  saas: require('@/assets/advantage/saas.png'),
  anchor: require('@/assets/advantage/anchor.png'),
  Live_broadcasting_room: require('@/assets/advantage/Live-broadcasting_room.png'),
  Live: require('@/assets/advantage/Live.png')
}

// 智能机器人案例
export const robotCase = [
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/character/bbc02.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_Shell.mp4',
    title: '智能虚拟客服',
    brand: '舒尔',
    type: 'vertical'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/bubaichi.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_JD.mp4',
    title: '智能虚拟社群助理',
    brand: '京东社群',
    type: 'vertical'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/character/bbc01.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_wenbo.mp4',
    title: '智能虚拟导游',
    brand: '文旅文博',
    type: 'vertical'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/character/bbc02.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_microsoft_imagine_cup.mp4',
    title: '智能创意营销',
    brand: '微软创新杯',
    type: 'vertical'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/character/bbc01.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_liepin.mp4',
    title: '智能虚拟员工',
    brand: '猎聘',
    type: 'horizontal'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/bubaichi.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/webcast/case_huanqiushibao.mp4',
    title: '智能虚拟主持人',
    brand: '环球时报',
    type: 'horizontal'
  }
]

// 合作伙伴
export const partnerVideoList = [
  {
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/partners.mp4',
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/partnersPost.png'
  },
  {
    label: "创业邦",
    img: require("../../assets/image/brandqingfeng.png"),
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/CYZone.mp4',
    poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/qingfeng.png'
  },
  {
    label: "定制祝福",
    img: require("../../assets/image/brandqingfeng.png"),
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Custom blessing.mp4',
    poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/qingfeng.png'
  },
  // {
  //   label: "清风",
  //   img: require("../../assets/image/brandqingfeng.png"),
  //   url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/%E6%B3%B0%E8%BF%AA%E6%B8%85%E9%A3%8E.mp4',
  //   poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/qingfeng.png'
  // },
  // {
  //   label: "zippo",
  //   img: require("../../assets/image/brandzippo.png"),
  //   url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Zippo.mp4',
  //   poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/zippo.png'
  // },
  // {label:"海信", img:require("../../assets/image/brandhaixin.png"),
  //   // video:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/%E6%B5%B7%E4%BF%A1.mp4',
  //   // poster:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/haixin.png'
  //   video:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Teddy.mp4',
  //   poster:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/Teddy.png'
  // },
  // {
  //   label: "圣元",
  //   img: require("../../assets/image/brandshengyuan.png"),
  //   url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/%E5%9C%A3%E5%85%83%E5%A5%B6%E7%B2%89.mp4',
  //   poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/shengyuan.png'
  // },
  // {
  //   label: "Teddy",
  //   img: require("../../assets/image/brandteddy.png"),
  //   url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/brand/Teddy.mp4',
  //   poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/Teddy.png'
  // },
  // {
  //   label: "bbc", img: "https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/bbcLogo.jpg",
  //   url: 'https://videos.magicscorp.com//Mvideo/Mwebsite/brand/bbcbrand.mp4',
  //   poster: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/brand/bbcbrand.png'
  // }
]

export const ottVideoList = [
  // {
  //   img:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/ott.png',
  //   url:'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/OTT%E5%B1%8F%E4%BF%9D%E5%B9%BF%E5%91%8Amp4.mp4'
  // },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/device.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/device-2020-04-24-172237.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/oppo.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/oppo%20--4s-demo.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/%E5%9C%A8%E7%BA%BF%E5%AA%92%E4%BD%93.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/%E5%9C%A8%E7%BA%BF%E5%AA%92%E4%BD%93%201.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/tixing.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/ReminderAD.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/haixin.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/ott/%E6%B5%B7%E4%BF%A1-%E8%AE%A9%E5%8F%AF%E5%8F%A3%E7%9A%84%E5%8F%98%E5%BE%97%E6%9B%B4%E5%8F%AF%E4%B9%90%EF%BC%88%E5%AE%B6%E5%BA%AD%E7%AF%87%EF%BC%89.mp4'
  },
]

export const mobileVideoList = [
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/boy.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/mobile/boy.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/buzhiqimei.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/mobile/buzhiqimei.mp4'
  },
  {
    img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/ottmobile/che.png',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mvideo/mobile/che.mp4'
  },
]

export const investorIcons = [
  require('@/assets/image/i501A.png'),
  require('@/assets/image/i502A.png'),
  require('@/assets/image/i503A.png'),
  require('@/assets/image/i504A.png'),
  require('@/assets/image/i505A.png')
]

// 创始人信息
export const founder = {
  img: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/background/WechatIMG9.jpeg',
  name: '伏英娜 （Leody Fu）',
  // introduce:'迈吉客科技创始人CEO兼董事长，TMT领域连续创业者，程序员出身女极客，中关村高端领军人才。曾于2004年离开索尼爱立信创办MoGenesis（创世互动）公司从事手机软件研发，三年内迅速成为移动设备软件领域的创新型成长公司，07年成功被Symbian（诺基亚）并购，之后在诺基亚建设和领导研发团队，直至绑定期结束被猎头邀请加盟微软。2010年在微软大中华区所负责的团队专注于微软前沿技术传播和推广，涵盖移动&互联网，云计算，智能手机，Xbox/Kinect等相关领域，2011年下半年即调至微软美国总部，在西雅图期间全面负责Windows Phone亚太区战略规划、重点合作伙伴项目及应用商店落地等，从零开始启动和构建了WP开发者生态。2014年回国创办迈吉客科技，致力于融合虚拟和现实，创造技术驱动的实时混合现实内容生产、分发和互动模式，帮助IP/品牌/内容生产者充分释放创意和想象，实现升维、高效、动态视觉化的思想及情感交流。'
  introduce: '迈吉客科技创始人CEO兼董事长，TMT领域连续创业者，程序员出身女极客，中关村高端领军人才/海英人才。2004年首次创业手机软件研发公司MoGenesis（创世互动），2007年被 Symbian(诺基亚)收购。后曾任微软美国总部及大中华区高管，负责前沿技术传播，从零开始构建了windows phone开发者生态。2014年底归国再次开始创业历程，希望在科技和人文的交叉路口，探索、发现、挖掘智能科技赋能相关领域数智升级的蓝海市场，以技术创新创造商业模式和增量价值。'
}
// 奖项、荣誉
export const honor = [
  {
    name: '高新技术企业',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/01.png'
  },
  {
    name: '中关村前沿技术企业',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/02.png'
  },
  {
    name: '2020年度优秀数字技术公司及大数据应用',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/03.png'
  },
  {
    name: '中关村高新技术企业',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/04.png'
  },
  {
    name: '2021年文化和旅游装备技术提升优秀项目',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/05.png'
  },
  {
    name: '歌华传媒杯·2020北京文化创意大赛总决赛三等奖',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/06.png'
  },
  {
    name: '第十二届(2020-2021)虎啸奖——年度最佳内容营销系统/工具/平台',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/07.png'
  },
  {
    name: '行业年度杰出贡献奖——伏英娜',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/08.png'
  },
  {
    name: '2021年度零售行业技术新锐企业',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/09.png'
  },
  {
    name: '2016年度中关村高端领军人才',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/10.png'
  },
  {
    name: '创意实践金奖',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/11.png'
  },
  {
    name: '',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/12.png'
  },
  {
    name: '',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/13.png'
  },
  {
    name: '',
    url: 'https://magics-large.oss-accelerate.aliyuncs.com/my-website/Mimg/honor/14.png'
  }
]
// export const toDo = '迈吉客科技是一家专注于人工智能技术应用于计算机视觉三维混合现实领域的成长型公司，一直致力于将科技创新应用于人文领域，以技术改变世界为信仰，以“释放创意和想象”为使命，希望以开放共享和利他共赢的模式构建生态，以智能科技激活内容生无穷创意，以生产方式的变革推动互动模式的升级和商业模式的创新，与合作伙伴共同探索和挖掘技术与艺术及文化碰撞所产生的跨界能量。迈吉客科技聚集了一批心怀梦想、勇于创新的年轻人，不畏挑战，勇往直前，以智慧和勇气，以使命和激情，以想象力和创业精神，不断创造和引领未来。'
export const toDo = '迈吉客科技是中关村前沿技术企业和国家级高新技术企业，2016年开始多年专注于智能虚拟化技术，以具备多模态智能交互能力的数字形象为载体实现AI+相关行业应用场景落地，已形成SaaS模式的产品矩阵和智能服务平台，助力各领域合作伙伴降本提效增量创收，目前产品线和应用场景覆盖短视频内容生产、智能虚拟直播及服务机器人，数智营销等，已服务品牌营销、电商零售新消费、文旅/文博/文创、媒体政府、金融和大健康等领域合作伙伴。'
// 为什么选择我们
export const chooseReasons = ['因为我们可以让你发挥你的优势。', '我们从不拿年龄说事！只要你有上佳的创造力和不服一切的自信！', '网虫死宅肿么了？我们需要你的死磕精神和爱因斯坦式的研究基因！', '我们尊重自由职业者，在您不方便全职就业的时候可以做我们的兼职顾问。']
